/* eslint-disable */
import "./App.css";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faSearch,
  faFileExcel,
  faFileAlt,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // State to toggle sidebar collapse
  const [starterProject, setStarterProject] = useState("");
  const [description, setDescription] = useState("");
  const [molecularProfile, setMolecularProfile] = useState("");
  const [flavor, setFlavor] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [ingredientFile, setIngredientFile] = useState(null);
  const [equipmentFile, setEquipmentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [status, setStatus] = useState("");
  const [chatInput, setChatInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [shortName, setShortName] = useState("");
  const [listofpapers, setListofpapers] = useState("");
  const [references, setReferences] = useState("");
  const [agentFreedom, setAgentfreedom] = useState(0);
  const [revisions, setRevisions] = useState(2);
  const [modelRevisions, setModelRevisions] = useState(1);

  const [activeSubmenu, setActiveSubmenu] = useState({
    protocol: false,
    optimize: false,
    analyse: false,
  });

  const [mode, setMOde] = useState({
    protocol: {
      creativeAssistance: false,
      DecisionSupport: false,
      StepByStep: false,
      newIP: false,
    },
    optimize: {
      cost: false,
      taste: false,
      texture: false,
      nutrition: false,
      sustainability: false,
      functionality: false,
      regulation: false,
    },
    analyse: {
      nutritionValue: false,
      carbonFootprint: false,
      ccompare: false,
      patent: false,
    },
  });

  const toggleSubmenu = (menu) => {
    if (isSidebarCollapsed) {
      setActiveSubmenu({ ...activeSubmenu, menu: true }); // Open submenu modal if collapsed
    } else {
      if (menu === "protocol")
        setActiveSubmenu({
          ...activeSubmenu,
          protocol: true,
          analyse: false,
          optimize: false,
        }); // Toggle submenu inside the sidebar if expanded
      if (menu === "analyse")
        setActiveSubmenu({
          ...activeSubmenu,
          analyse: true,
          protocol: false,
          optimize: false,
        }); // Toggle submenu inside the sidebar if expanded
      if (menu === "optimize")
        setActiveSubmenu({
          ...activeSubmenu,
          optimize: true,
          protocol: false,
          analyse: false,
        }); // Toggle submenu inside the sidebar if expanded
    }
  };

  const handleCloseModal = () => {
    setActiveSubmenu(""); // Close submenu modal
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setResult("");
    setChatHistory([]);
    setChatInput("");
    setShortName("");

    const queryParams = new URLSearchParams({
      gene_name: starterProject,
      disease_input: description,
      output_variable_input: molecularProfile,
      hypothesis_type: flavor,
      known_hypotheses_input: additionalInfo,
      tmp: agentFreedom,
      itr_a: revisions,
      itr_m: modelRevisions,
    }).toString();

    const endpoint = `https://algenius-api.northeurope.cloudapp.azure.com/api/v1/generate-hypotheses?${queryParams}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
      });

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let resultData = "";

        // Stream the response in chunks
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setStatus("Complete");
            break;
          }
          if (value) {
            setShowResult(true);
          }
          const chunk = decoder.decode(value, { stream: true });
          const messages = chunk.trim().split("\n");
          messages.forEach((message) => {
            const parsedMessage = JSON.parse(message);
            if (parsedMessage.type === "AIMessage") {
              resultData = "";
              setLoading(false);
              resultData += parsedMessage.data;
              setResult(resultData);
              setStatus("Generating");
            }
            if (parsedMessage.type === "ToolMessage") {
              setStatus("Executing tools");
              setLoading(true);
            }
            if (parsedMessage.type === "HumanMessage") {
              setStatus("Revising");
              setLoading(true);
            }
            if (parsedMessage.type === "Name") {
              setShortName(parsedMessage.data);
              setListofpapers(parsedMessage.listofpapers);
              setReferences(parsedMessage.references);
            }
          });
        }
      } else {
        setResult("Error: Failed to generate protocols.");
      }
    } catch (error) {
      setResult("Error: Failed to generate protocols.");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setShowResult(false);
    setStarterProject("");
    setDescription("");
    setMolecularProfile("");
    setFlavor("");
    setAdditionalInfo("");
    setIngredientFile(null);
    setEquipmentFile(null);
    setResult("");
    setListofpapers("");
    setReferences("");
    setRevisions(2);
    setModelRevisions(1);
    setChatHistory([]);
  };

  useEffect(() => {
    handleReset();
    if (mode.optimize.cost)
      setChatInput("Optimize the production cost for the following protocol ");
    else if (mode.optimize.taste)
      setChatInput("Optimize the taste for the following protocol ");
    else if (mode.optimize.texture)
      setChatInput("Optimize the texture for the following protocol ");
    else if (mode.optimize.nutrition)
      setChatInput("Optimize the nutrition for the following protocol ");
    else if (mode.optimize.functionality)
      setChatInput(
        "Optimize the given functionality for the following protocol "
      );
    else if (mode.optimize.regulation)
      setChatInput(
        "Optimize the compilance with food legislations for the following protocol "
      );
    else if (mode.optimize.sustainability)
      setChatInput("Optimize the sustainability for the following protocol ");
    else if (mode.analyse.nutritionValue)
      setChatInput("Analyse the nutritional value for the following protocol ");
    else if (mode.analyse.carbonFootprint)
      setChatInput("Analyse the carbon footprint of the given protocol");
    else if (mode.analyse.ccompare)
      setChatInput("compare the given protocols");
    else if (mode.analyse.patent)
      setChatInput("Verify the given protocol is novel and eligible for patent");
    else setChatInput("");
  }, [mode]);

  const handleChatSubmit = async () => {
    if (chatInput.trim() === "") return;

    setChatHistory((prevHistory) => [
      ...prevHistory,
      { sender: "User", text: chatInput },
    ]);

    const userMessage = chatInput;
    setChatInput("");

    setLoading(true);

    try {
      const response = await fetch("https://algenius-api.northeurope.cloudapp.azure.com/api/v1/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: userMessage,
          protocol: result,
          short_name: shortName,
        }),
      });

      const data = await response.json();

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "AI", text: data.reply },
      ]);
      setShowResult(true);
    } catch (error) {
      console.error("Error during chat submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFreedom = (event, newValue) => {
    setAgentfreedom(newValue);
  };

  const getUploadSection = () => {
    return (
      <div className="left-section">
        <div className="upload-section">
          <label className="upload-label">
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setIngredientFile)}
              className="upload-input"
            />
            <span className="upload-icon">+</span>
            <span className="upload-text">Upload Ingredient List</span>
          </label>
        </div>
        <div className="upload-section">
          <label className="upload-label">
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setEquipmentFile)}
              className="upload-input"
            />
            <span className="upload-icon">+</span>
            <span className="upload-text">Upload Equipment List</span>
          </label>
        </div>
      </div>
    );
  };

  const getCreativeAssistance = () => {
    return (
      <>
       <div className="section-container">
        <div className="section-main">
          <div>Brainstorm</div>
          <div className="section-child-desc">
            AI moderator to help your team to explore ideas effectively
          </div>
        </div>
        <div className="section-main">
          <div>Experiment failure analysis</div>
          <div className="section-child-desc">
            Diagnose issues with failed experiment.
            Get recomendations for next experiments.
          </div>
        </div>
        <div className="section-main">
          <div>Recommend alternative approaches</div>
          <div className="section-child-desc">
            Explore other ingredients and methods
          </div>
        </div>
        <div className="section-main">
          <div>Estimate market success</div>
          <div className="section-child-desc">
            Explore other ingredients and methods
          </div>
        </div>
        </div>
      </>
    );
  };

  const getDecisionSupport = () => {
    return (
      <>
        <div className="section-main">
          <div>Data driven decision</div>
          <div className="section-child-desc">
            Agent that can access market data and help make decisions
          </div>
        </div>
      </>
    );
  };

  const getGenerateNewIP = () => {
    return (
      <>
        <div className="section-main">
          <div>
            <div>Level of innovation</div>
            <Stack
              spacing={2}
              direction="row"
              sx={{ alignItems: "center", mb: 1 }}
            >
              <span>incremental</span>
              <Slider
                aria-label="freedom"
                defaultValue={0}
                valueLabelDisplay="auto"
                shiftStep={0.1}
                step={0.1}
                marks
                min={0}
                max={1}
                value={agentFreedom}
                onChange={handleChangeFreedom}
              />
              <span>radical</span>
            </Stack>
          </div>
          <div>
            <span>Number of creative cycles</span>
            <Stack
              spacing={2}
              direction="row"
              sx={{ alignItems: "center", mb: 1 }}
            >
              <span>0</span>
              <Slider
                aria-label="revisions"
                defaultValue={0}
                valueLabelDisplay="auto"
                shiftStep={1}
                step={1}
                marks
                min={0}
                max={5}
                value={revisions}
                onChange={(e, value) => setRevisions(value)}
              />
              <span>5</span>
            </Stack>
          </div>
          <div>
            <span>Number of revisions</span>
            <Stack
              spacing={2}
              direction="row"
              sx={{ alignItems: "center", mb: 1 }}
            >
              <span>0</span>
              <Slider
                aria-label="revisions"
                defaultValue={0}
                valueLabelDisplay="auto"
                shiftStep={1}
                step={1}
                marks
                min={0}
                max={5}
                value={modelRevisions}
                onChange={(e, value) => setModelRevisions(value)}
              />
              <span>5</span>
            </Stack>
          </div>
        </div>
        {getDefaultProtocolGenerationForm()}
      </>
    );
  };

  const getDefaultProtocolGenerationForm = () => {
    return (
      <div className="right-section">
        <div className="input-field">
          <input
            type="text"
            value={starterProject}
            onChange={(e) => setStarterProject(e.target.value)}
            placeholder="Enter starter project"
          />
        </div>
        <div className="input-field">
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description of the product"
          />
        </div>
        <div className="input-field">
          <input
            type="text"
            value={molecularProfile}
            onChange={(e) => setMolecularProfile(e.target.value)}
            placeholder="Enter molecular profile"
          />
        </div>
        <div className="input-field">
          <input
            type="text"
            value={flavor}
            onChange={(e) => setFlavor(e.target.value)}
            placeholder="Enter the flavor to achieve"
          />
        </div>
        <div className="input-field">
          <input
            type="text"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Additional Information"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="generate-button"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate Protocols"}
        </button>
      </div>
    );
  };

  const getFileAndChat = () => {
    return (
      <>
        <div className="left-section">
          <div className="upload-section">
            <label className="upload-label">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setEquipmentFile)}
                className="upload-input"
              />
              <span className="upload-icon">+</span>
              <span className="upload-text">Upload protocol</span>
            </label>
          </div>
        </div>
        <div className="right-section">
          {/* <div className="chat-input-container"> */}
          <div className="input-field">
            <input
              type="text"
              value={result}
              onChange={(e) => setResult(e.target.value)}
              placeholder="Upload or paste your protocol"
              className="chat-input"
            />
          </div>
          <div className="input-field">
            <input
              type="text"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder="Upload or paste your protocol"
              className="chat-input"
            />
          </div>

          <button onClick={handleChatSubmit} className="chat-submit-button">
            Send
          </button>
        </div>
        {/* </div> */}
      </>
    );
  };

  return (
    <div className="App">
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <ul>
          <li onClick={() => toggleSubmenu("protocol")}>
            <div className="menu-item">
              <FontAwesomeIcon icon={faFile} />
              <span>Generate new protocol</span>
            </div>
            {!isSidebarCollapsed && activeSubmenu.protocol && (
              <ul className="submenu">
                <li>
                  <div
                    className={`submenu-item ${
                      mode.protocol.creativeAssistance ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        protocol: {
                          creativeAssistance: true,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Creative assistance
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.protocol.DecisionSupport ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: true,
                          StepByStep: false,
                          newIP: false,
                        },
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Decision support
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.protocol.StepByStep ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: true,
                          newIP: false,
                        },
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Design step by step protocol
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.protocol.newIP ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: true,
                        },
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Generate new IP
                  </div>
                </li>
              </ul>
            )}
          </li>
          <li onClick={() => toggleSubmenu("optimize")}>
            <div className="menu-item">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Optimize protocol</span>
            </div>
            {!isSidebarCollapsed && activeSubmenu.optimize && (
              <ul className="submenu">
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.cost ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: true,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                          regulation: false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize cost
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.taste ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: true,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                          regulation: false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize taste
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.texture ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: true,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                          regulation: false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize texture
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.nutrition ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: true,
                          sustainability: false,
                          functionality: false,
                          regulation: false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize nutrition
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.sustainability ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: true,
                          functionality: false,
                          regulation:false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize sustainability
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.functionality ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: true,
                          regulation: false
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Optimize functionality
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.optimize.functionality ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                          regulation: true
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Regulatory compilance
                  </div>
                </li>
              </ul>
            )}
          </li>
          <li onClick={() => toggleSubmenu("analyse")}>
            <div className="menu-item">
              <FontAwesomeIcon icon={faFileExcel} />
              <span>Analyse formulations</span>
            </div>
            {!isSidebarCollapsed && activeSubmenu.analyse && (
              <ul className="submenu">
                <li>
                  <div
                    className={`submenu-item ${
                      mode.analyse.nutritionValue ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: true,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Calculate nutrition value
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.analyse.carbonFootprint ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: true,
                          ccompare: false,
                          patent: false,
                        },
                      })
                    }
                  >
                    Assess carbon footprint
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.analyse.ccompare ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: true,
                          patent: false,
                        },
                      })
                    }
                  >
                    Compare formulations
                  </div>
                </li>
                <li>
                  <div
                    className={`submenu-item ${
                      mode.analyse.patent ? "active" : ""
                    }`}
                    onClick={() =>
                      setMOde({
                        ...mode,
                        optimize: {
                          cost: false,
                          taste: false,
                          texture: false,
                          nutrition: false,
                          sustainability: false,
                          functionality: false,
                        },
                        protocol: {
                          creativeAssistance: false,
                          DecisionSupport: false,
                          StepByStep: false,
                          newIP: false,
                        },
                        analyse: {
                          nutritionValue: false,
                          carbonFootprint: false,
                          ccompare: false,
                          patent: true,
                        },
                      })
                    }
                  >
                    Check for patent
                  </div>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>

      {/* Submenu Modal */}
      {isSidebarCollapsed && activeSubmenu && (
        <div className="submenu-modal">
          <div className="submenu-modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h3>
              {activeSubmenu === "protocol"
                ? "Protocol Options"
                : activeSubmenu === "optimize"
                ? "Optimize Options"
                : "Analyse Options"}
            </h3>
            <ul>
              {activeSubmenu === "protocol" && (
                <>
                  <li>
                    <div>Option 1</div>
                  </li>
                  <li>
                    <div>Option 2</div>
                  </li>
                </>
              )}
              {activeSubmenu === "optimize" && (
                <>
                  <li>
                    <div>Option 1</div>
                  </li>
                  <li>
                    <div>Option 2</div>
                  </li>
                </>
              )}
              {activeSubmenu === "analyse" && (
                <>
                  <li>
                    <div>Option 1</div>
                  </li>
                  <li>
                    <div>Option 2</div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}

      {/* Sidebar toggle button */}
      {/* <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarCollapsed ? '->' : '<-'}
      </button> */}

      <div className={`main-content ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <nav className="navbar">
          <div className="navbar-logo">
            <h1>Algenius Foods</h1>
          </div>
        </nav>
        <header className="App-header">
          <div className={`overlay ${loading ? "active" : ""}`}>
            <div className="loading-spinner"></div>
          </div>
          {!showResult ? (
            <div className="container">
              {false && getUploadSection()}
              {mode.protocol.StepByStep && getDefaultProtocolGenerationForm()}
              {mode.protocol.creativeAssistance && getCreativeAssistance()}
              {mode.protocol.DecisionSupport && getDecisionSupport()}
              {mode.protocol.newIP && getGenerateNewIP()}

              {mode.optimize.cost && getFileAndChat()}
              {mode.optimize.taste && getFileAndChat()}
              {mode.optimize.texture && getFileAndChat()}
              {mode.optimize.nutrition && getFileAndChat()}
              {mode.optimize.sustainability && getFileAndChat()}
              {mode.optimize.functionality && getFileAndChat()}
              {mode.optimize.regulation && getFileAndChat()}

              {mode.analyse.nutritionValue && getFileAndChat()}
              {mode.analyse.carbonFootprint && getFileAndChat()}
              {mode.analyse.ccompare && getFileAndChat()}
              {mode.analyse.patent && getFileAndChat()}
            </div>
          ) : (
            <>
              <div className="result-container">
                <div>Status: {status}</div>
                <div className="result">
                  <ReactMarkdown>{result}</ReactMarkdown>
                  {listofpapers && (
                    <>
                      {" "}
                      <h3>Private References</h3>
                      <ReactMarkdown>{listofpapers}</ReactMarkdown>
                    </>
                  )}
                  {references && (
                    <>
                      {" "}
                      <h3>Public References</h3>
                      <ReactMarkdown>{references}</ReactMarkdown>
                    </>
                  )}
                </div>
                {status === "Complete" && (
                  <div className="chat-container">
                    <div className="chat-history">
                      {chatHistory.map((message, index) => (
                        <div key={index} className="chat-message">
                          <strong>{message.sender}:</strong>
                          <ReactMarkdown>{message.text}</ReactMarkdown>
                        </div>
                      ))}
                    </div>
                    <div className="chat-input-container">
                      <input
                        type="text"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        placeholder="Type your question here..."
                        className="chat-input"
                      />
                      <button
                        onClick={handleChatSubmit}
                        className="chat-submit-button"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <button onClick={handleReset} className="reset-button">
                Generate New Protocol
              </button>
            </>
          )}
        </header>
        <footer className="App-footer">
          <p>&copy; 2024 Algenius Foods. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
